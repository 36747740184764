import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../components/mdx-page';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Documentation`}</h1>
    <p>{`Kactus is built on top of the giant called `}<a parentName="p" {...{
        "href": "https://git-scm.com"
      }}><inlineCode parentName="a">{`git`}</inlineCode></a>{`. That's why in order to use Kactus efficiently, we will need to learn some concepts used by `}<inlineCode parentName="p">{`git`}</inlineCode>{`. While it is a very powerful technology and as such can be overwhelming at first, we just need a few key concepts to start with.`}</p>
    <p>{`Here is a quick `}<a parentName="p" {...{
        "href": "/help/glossary"
      }}>{`glossary`}</a>{` of specific terms that we use across the documentation.`}</p>
    <hr></hr>
    <h2>{`How does it work?`}</h2>
    <p>{`That's a good question. In a seemigly magical fashion, Kactus transforms your sketch files into separate text files. One for each layer. This allows `}<inlineCode parentName="p">{`git`}</inlineCode>{` to track them, merge them, compare them.`}</p>
    <p>{`Read more about `}<a parentName="p" {...{
        "href": "/help/how-to"
      }}>{`how to work with Kactus`}</a>{`.`}</p>
    <hr></hr>
    <h2>{`Configuration`}</h2>
    <p>{`You can do a lot of things with Kactus: share one or multiple pages across your sketch files, share the text styles, etc..`}</p>
    <p>{`All this configuration is done via a file called `}<inlineCode parentName="p">{`kactus.json`}</inlineCode>{`. See what its format is `}<a parentName="p" {...{
        "href": "/help/kactus-dot-json"
      }}>{`here`}</a>{`.`}</p>
    <hr></hr>
    <h2>{`// TODO`}</h2>
    <p><a parentName="p" {...{
        "href": "/contact"
      }}>{`Hit me`}</a>{` with what doesn't seem clear and I'll prioritize it!`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      